<template>
  <div
    v-bind:class="
      $vuetify.breakpoint.smAndDown ? 'card_version_price_height' : ''
    "
    class=""
  >
    <v-row no-gutters>
      <!-- Montly Price -->
      <!-- If '12m' getMontlyDiscountedPrice -->
      <v-col cols="12" class="full_price py-4">
        <!-- If '1m' getDiscountedPrice -->
        <span class="montly_price" v-if="selected_billing_type == '1m'">
          <v-row no-gutters>
            <v-col cols="12" class="text-right pr-8">
              <span class="euro_value">
                {{ getDiscountedPrice(version) }}
              </span>
              <span class="euro_symbol_large">€</span>
            </v-col>
            <v-col class="text-right pr-8">
              <span class="price_per_period"> /{{ $t("lp_month") }} </span>
            </v-col>
          </v-row>
          <!-- <span class="price_per_period"> /{{ $t("lp_month") }} </span> -->
        </span>

        <!-- If '12m' getMontlyDiscountedPrice -->
        <span class="yearly_price" v-if="selected_billing_type == '12m'">
          <v-row no-gutters>
            <v-col cols="12" class="text-right pr-8">
              <span class="euro_value">{{ getDiscountedPrice(version) }}</span>
              <span class="euro_symbol_large">€</span>
            </v-col>
            <v-col class="text-right pr-8">
              <span class="price_per_period"> /{{ $t("lp_year") }} </span>
            </v-col>
          </v-row>
        </span>

        <!-- If '12m' getMontlyDiscountedPrice -->
        <!-- <span class="montly_price" v-if="selected_billing_type == '12m'">
          {{ getMontlyDiscountedPrice(version) }}
          <span class="euro_symbol_large">€</span>
        </span> -->
      </v-col>

      <!-- If discounted show percentage -->
      <v-col
        cols="12"
        class="text-center discount_value"
        v-if="getDiscount(version) != 0"
      >
        {{ $t("lp_discount") }}
        {{ getDiscount(version) }}%
      </v-col>

      <!-- If discounted show euro savings -->
      <v-col
        cols="12"
        class="text-center discount_saving"
        v-if="getDiscount(version) != 0"
      >
        {{ $t("lp_saving") }}
        {{ getPrice(version) - getDiscountedPrice(version) }}€<span
          v-if="selected_billing_type == '1m'"
          >/{{ $t("lp_month") }}</span
        ><span v-if="selected_billing_type == '12m'"
          >/{{ $t("lp_year") }}
        </span>
      </v-col>

      <!-- If '12m' show year price due -->
      <!-- <v-col
        cols="12"
        class="text-center mt-4"
        v-if="selected_billing_type == '12m'"
      >
        <span class="yearly_billed_price">{{
          getDiscountedPrice(version)
        }}</span
        ><span class="euro_symbol_small">€</span>
        <br />
        <span class="billed_yearly">
          {{ $t("lp_billed_yearly") }}
        </span>
        <br />
        <span class="mt-8 strikethrough">
          ({{ $t("lp_billed_yearly_instead_of") }}
          {{ getPrice(version, "1m") * 12 }}€<span class=""
            >/{{ $t("lp_year") }})
          </span>
        </span>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import app_versions_features from "@/mixins/app_versions_features";

export default {
  mixins: [app_versions_features],

  props: {
    selected_billing_type: {
      type: String,
    },
    version: {
      type: String,
    },
  },
  components: {},
  data: () => ({}),
  methods: {},
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped>
.card_version_price_height {
  height: 140px;
}

.full_price {
  margin-top: 1rem;
  margin-bottom: -8px;
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.75);
  /* text-align: right; */
  /* margin-right:8rem; */
}

.yearly_billed_price {
  font-weight: bold;
  font-size: 2rem;
}

.billed_yearly {
  font-size: 1rem;
  font-weight: 400;
}

.euro_symbol_large {
  font-size: 1.4rem;
  font-weight: 400;
  top: -20px;
  left: -2px;
  position: relative;
}

.euro_symbol_small {
  font-size: 0.9rem;
  font-weight: bold;
  top: -5px;
  position: relative;
}

.montly_price {
  /* position: relative; */
  /* right: -90px; */
  font-weight: 800;
  font-size: 2.5rem;
}

.yearly_price {
  /* position: relative; */
  /* right: -90px; */
  font-weight: 800;
  font-size: 2.5rem;
}

.price_per_period {
  font-size: 1rem;
  font-weight: 400;
}

.discount_value {
  color: red;
}

.discount_saving {
  color: red;
  font-weight: bold;
}

.saving_compared_to_montly {
  color: red;
  font-weight: bold;
}

.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
  border-color: rgba(255, 0, 0, 0.5);

  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}
</style>