var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"card_smallbusiness"},[_c('v-card-text',{staticClass:"text-center"},[_c('span',{staticClass:"select_subscription_type"},[_vm._v(" "+_vm._s(_vm.$t("lp_select_subscription_type"))+" ")]),_c('v-btn-toggle',[_c('v-btn',{attrs:{"height":"25","min-width":"100","dark":_vm.selected_billing_type == '1m'},on:{"click":function($event){_vm.selected_billing_type = '1m'}}},[_c('span',{class:_vm.selected_billing_type == '1m'
                    ? 'secondary--text'
                    : 'primary--text'},[_vm._v(" "+_vm._s(_vm.$t("lp_monthly"))+" ")])]),_c('v-btn',{attrs:{"height":"25","min-width":"100","dark":_vm.selected_billing_type == '12m'},on:{"click":function($event){_vm.selected_billing_type = '12m'}}},[_c('span',{class:_vm.selected_billing_type == '12m'
                    ? 'secondary--text'
                    : 'primary--text'},[_vm._v(" "+_vm._s(_vm.$t("lp_yearly"))+" ")])])],1)],1),_c('v-card-title',{staticClass:"version_name"},[_vm._v(" SmallBusiness ")]),_c('v-card-text',{staticClass:"text-center"},[_c('span',{staticClass:"for_who"},[_vm._v(" "+_vm._s(_vm.$t("lp_for_who_smallbusiness"))+" ")]),_c('cardVersionPrice',{staticClass:"version_price",attrs:{"selected_billing_type":_vm.selected_billing_type,"version":"smallbusiness"}}),_c('hr',{staticClass:"mt-2 mb-6"}),_vm._l((_vm.app_versions_features),function(included_feature){return _c('v-row',{key:included_feature.feature_name,staticClass:"text-center feature_row",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"9"}},[_c('span',{staticClass:"feature_description caption"},[_vm._v(" "+_vm._s(included_feature.feature_name)+" ")]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"90%","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(included_feature.feature_tooltip))])])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"3"}},[(
                  included_feature.small_business === false ||
                  included_feature.small_business == 0
                )?_c('span',[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-close ")])],1):(included_feature.small_business === true)?_c('span',[_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v(" mdi-check ")])],1):(included_feature.small_business === 999)?_c('span',[_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v(" mdi-infinity ")])],1):_c('span',{staticClass:"span_feature_value"},[_vm._v(_vm._s(included_feature.small_business))])])],1)})],2)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"card_standard"},[_c('v-card-text',{staticClass:"text-center"},[_c('span',{staticClass:"select_subscription_type"},[_vm._v(" "+_vm._s(_vm.$t("lp_select_subscription_type"))+" ")]),_c('v-btn-toggle',[_c('v-btn',{attrs:{"height":"25","min-width":"100","dark":_vm.selected_billing_type == '1m'},on:{"click":function($event){_vm.selected_billing_type = '1m'}}},[_c('span',{class:_vm.selected_billing_type == '1m'
                    ? 'secondary--text'
                    : 'primary--text'},[_vm._v(" "+_vm._s(_vm.$t("lp_monthly"))+" ")])]),_c('v-btn',{attrs:{"height":"25","min-width":"100","dark":_vm.selected_billing_type == '12m'},on:{"click":function($event){_vm.selected_billing_type = '12m'}}},[_c('span',{class:_vm.selected_billing_type == '12m'
                    ? 'secondary--text'
                    : 'primary--text'},[_vm._v(" "+_vm._s(_vm.$t("lp_yearly"))+" ")])])],1)],1),_c('v-card-title',{staticClass:"version_name"},[_vm._v(" Standard ")]),_c('v-card-text',{staticClass:"text-center"},[_c('span',{staticClass:"for_who"},[_vm._v(" "+_vm._s(_vm.$t("lp_for_who_standard"))+" ")]),_c('cardVersionPrice',{staticClass:"version_price",attrs:{"selected_billing_type":_vm.selected_billing_type,"version":"standard"}}),_c('hr',{staticClass:"mt-2 mb-6"}),_vm._l((_vm.app_versions_features),function(included_feature){return _c('v-row',{key:included_feature.feature_name,staticClass:"text-center feature_row",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"9"}},[_c('span',{staticClass:"feature_description caption"},[_vm._v(" "+_vm._s(included_feature.feature_name)+" ")]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"90%","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(included_feature.feature_tooltip))])])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"3"}},[(
                  included_feature.standard === false ||
                  included_feature.standard == 0
                )?_c('span',[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-close ")])],1):(included_feature.standard === true)?_c('span',[_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v(" mdi-check ")])],1):(included_feature.standard === 999)?_c('span',[_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v(" mdi-infinity ")])],1):_c('span',{staticClass:"span_feature_value"},[_vm._v(_vm._s(included_feature.standard))])])],1)})],2)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"card_enterprise"},[_c('v-card-text',{staticClass:"text-center"},[_c('span',{staticClass:"select_subscription_type"},[_vm._v(" "+_vm._s(_vm.$t("lp_select_subscription_type"))+" ")]),_c('v-btn-toggle',[_c('v-btn',{attrs:{"height":"25","min-width":"100","dark":_vm.selected_billing_type == '1m'},on:{"click":function($event){_vm.selected_billing_type = '1m'}}},[_c('span',{class:_vm.selected_billing_type == '1m'
                    ? 'secondary--text'
                    : 'primary--text'},[_vm._v(" "+_vm._s(_vm.$t("lp_monthly"))+" ")])]),_c('v-btn',{attrs:{"height":"25","min-width":"100","dark":_vm.selected_billing_type == '12m'},on:{"click":function($event){_vm.selected_billing_type = '12m'}}},[_c('span',{class:_vm.selected_billing_type == '12m'
                    ? 'secondary--text'
                    : 'primary--text'},[_vm._v(" "+_vm._s(_vm.$t("lp_yearly"))+" ")])])],1)],1),_c('v-card-title',{staticClass:"version_name"},[_vm._v(" Enterprise ")]),_c('v-card-text',{staticClass:"text-center"},[_c('span',{staticClass:"for_who"},[_vm._v(" "+_vm._s(_vm.$t("lp_for_who_enterprise"))+" ")]),_c('cardVersionPrice',{staticClass:"version_price",attrs:{"selected_billing_type":_vm.selected_billing_type,"version":"enterprise"}}),_c('hr',{staticClass:"mt-2 mb-6"}),_vm._l((_vm.app_versions_features),function(included_feature){return _c('v-row',{key:included_feature.feature_name,staticClass:"text-center feature_row",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"9"}},[_c('span',{staticClass:"feature_description caption"},[_vm._v(" "+_vm._s(included_feature.feature_name)+" ")]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"90%","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(included_feature.feature_tooltip))])])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"3"}},[(
                  included_feature.enterprise === false ||
                  included_feature.enterprise == 0
                )?_c('span',[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-close ")])],1):(included_feature.enterprise === true)?_c('span',[_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v(" mdi-check ")])],1):(included_feature.enterprise === 999)?_c('span',[_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v(" mdi-infinity ")])],1):_c('span',{staticClass:"span_feature_value"},[_vm._v(_vm._s(included_feature.enterprise))])])],1)})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }