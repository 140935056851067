import { findWithAttr } from "@/modules/find_with_attr";
const round = (n, dp) => {
    const h = +('1'.padEnd(dp + 1, '0')) // 10 or 100 or 1000 or etc
    return Math.round(n * h) / h
}
export default {
    // data: () => ({
    //     selected_billing_type: "12m",
    // }),
    computed: {
        app_versions_features() {
            return [
                {
                    feature_name: this.$t('avf_total_areas'),
                    feature_tooltip: this.$t('avf_total_areas_tooltip'),
                    small_business: this.getIncludedAreasCount('smallbusiness'),
                    standard: this.getIncludedAreasCount('standard'),
                    enterprise: this.getIncludedAreasCount('enterprise'),
                },
                {
                    feature_name: this.$t('avf_total_signs'),
                    feature_tooltip: this.$t('avf_total_signs_tooltip'),
                    small_business: 999,
                    standard: 999,
                    enterprise: 999,
                },

                {
                    feature_name: this.$t('avf_event_history_count'),
                    feature_tooltip: this.$t('avf_event_history_count_tooltip'),
                    small_business: this.getIncludedEventVersionCount('smallbusiness'),
                    standard: this.getIncludedEventVersionCount('standard'),
                    enterprise: this.getIncludedEventVersionCount('enterprise'),
                },
                {
                    feature_name: this.$t('avf_event_images'),
                    feature_tooltip: this.$t('avf_event_images_tooltip'),
                    small_business: this.getIncludedEventImagesCount('smallbusiness'),
                    standard: this.getIncludedEventImagesCount('standard'),
                    enterprise: this.getIncludedEventImagesCount('enterprise'),
                },
                {
                    feature_name: this.$t('avf_max_montly_reports'),
                    feature_tooltip: this.$t('avf_max_montly_reports_tooltip'),
                    small_business: this.getIncludedMontlyReportsCount('smallbusiness'),
                    standard: this.getIncludedMontlyReportsCount('standard'),
                    enterprise: this.getIncludedMontlyReportsCount('enterprise'),
                },
                {
                    feature_name: this.$t('avf_max_translation_languages'),
                    feature_tooltip: this.$t('avf_max_translation_languages_tooltip'),
                    small_business: this.getIncludedTranslationLanguagesCount('smallbusiness'),
                    standard: this.getIncludedTranslationLanguagesCount('standard'),
                    enterprise: this.getIncludedTranslationLanguagesCount('enterprise'),
                },
                {
                    feature_name: this.$t('avf_report_images'),
                    feature_tooltip: this.$t('avf_report_images_tooltip'),
                    small_business: this.getIncludedReportImagesCount('smallbusiness'),
                    standard: this.getIncludedReportImagesCount('standard'),
                    enterprise: this.getIncludedReportImagesCount('enterprise'),
                },
                {
                    feature_name: this.$t('avf_report_precise_gps'),
                    feature_tooltip: this.$t('avf_report_precise_gps_tooltip'),
                    small_business: this.getIncludedReportPreciseGPS('smallbusiness'),
                    standard: this.getIncludedReportPreciseGPS('standard'),
                    enterprise: this.getIncludedReportPreciseGPS('enterprise'),
                },
            ]
        },
    },
    methods: {
        getIncludedAreasCount(licence_type) {
            // licence_type should be somethings like:
            // standard, enterprise
            const fullname_licence_type = licence_type + "_" + this.selected_billing_type;
            const index_licence_type = findWithAttr(
                this.pricing_licences_list,
                "id",
                fullname_licence_type
            );
            if (index_licence_type > -1) {
                const licence_doc = this.pricing_licences_list[index_licence_type];
                // Check if current plant have a custom_prices and also a custom included area_count
                const index_custom_price = findWithAttr(
                    licence_doc.custom_prices,
                    "plant",
                    this.selectedPlant
                );
                if (index_custom_price > -1) {
                    if (
                        licence_doc.custom_prices[index_custom_price].included &&
                        licence_doc.custom_prices[index_custom_price].included.area_count
                    ) {
                        return licence_doc.custom_prices[index_custom_price].included
                            .area_count;
                    }
                }
                return licence_doc.included.area_count;
            }
            return null;
        },
        getIncludedEventVersionCount(licence_type) {
            // licence_type should be somethings like:
            // standard, enterprise
            const fullname_licence_type = licence_type + "_" + this.selected_billing_type;
            const index_licence_type = findWithAttr(
                this.pricing_licences_list,
                "id",
                fullname_licence_type
            );
            if (index_licence_type > -1) {
                const licence_doc = this.pricing_licences_list[index_licence_type];
                // Check if current plant have a custom_prices and also a custom included event_history_count
                const index_custom_price = findWithAttr(
                    licence_doc.custom_prices,
                    "plant",
                    this.selectedPlant
                );
                if (index_custom_price > -1) {
                    if (
                        licence_doc.custom_prices[index_custom_price].included &&
                        licence_doc.custom_prices[index_custom_price].included.event_history_count
                    ) {
                        return licence_doc.custom_prices[index_custom_price].included
                            .event_history_count;
                    }
                }
                return licence_doc.included.event_history_count;
            }
            return null;
        },
        getIncludedEventImagesCount(licence_type) {
            // licence_type should be somethings like:
            // standard, enterprise
            const fullname_licence_type = licence_type + "_" + this.selected_billing_type;
            const index_licence_type = findWithAttr(
                this.pricing_licences_list,
                "id",
                fullname_licence_type
            );
            if (index_licence_type > -1) {
                const licence_doc = this.pricing_licences_list[index_licence_type];
                // Check if current plant have a custom_prices and also a custom included event_image_count
                const index_custom_price = findWithAttr(
                    licence_doc.custom_prices,
                    "plant",
                    this.selectedPlant
                );
                if (index_custom_price > -1) {
                    if (
                        licence_doc.custom_prices[index_custom_price].included &&
                        licence_doc.custom_prices[index_custom_price].included.event_image_count
                    ) {
                        return licence_doc.custom_prices[index_custom_price].included
                            .event_image_count;
                    }
                }
                return licence_doc.included.event_image_count;
            }
            return null;
        },
        getIncludedMontlyReportsCount(licence_type) {
            // licence_type should be somethings like:
            // standard, enterprise
            const fullname_licence_type = licence_type + "_" + this.selected_billing_type;
            const index_licence_type = findWithAttr(
                this.pricing_licences_list,
                "id",
                fullname_licence_type
            );
            if (index_licence_type > -1) {
                const licence_doc = this.pricing_licences_list[index_licence_type];
                // Check if current plant have a custom_prices and also a custom included montly_reports
                const index_custom_price = findWithAttr(
                    licence_doc.custom_prices,
                    "plant",
                    this.selectedPlant
                );
                if (index_custom_price > -1) {
                    if (
                        licence_doc.custom_prices[index_custom_price].included &&
                        licence_doc.custom_prices[index_custom_price].included.montly_reports
                    ) {
                        return licence_doc.custom_prices[index_custom_price].included
                            .montly_reports;
                    }
                }
                return licence_doc.included.montly_reports;
            }
            return null;
        },
        getIncludedTranslationLanguagesCount(licence_type) {
            // licence_type should be somethings like:
            // standard, enterprise
            const fullname_licence_type = licence_type + "_" + this.selected_billing_type;
            const index_licence_type = findWithAttr(
                this.pricing_licences_list,
                "id",
                fullname_licence_type
            );
            if (index_licence_type > -1) {
                const licence_doc = this.pricing_licences_list[index_licence_type];
                // Check if current plant have a custom_prices and also a custom included max_languages
                const index_custom_price = findWithAttr(
                    licence_doc.custom_prices,
                    "plant",
                    this.selectedPlant
                );
                if (index_custom_price > -1) {
                    if (
                        licence_doc.custom_prices[index_custom_price].included &&
                        licence_doc.custom_prices[index_custom_price].included.max_languages
                    ) {
                        return licence_doc.custom_prices[index_custom_price].included
                            .max_languages;
                    }
                }
                return licence_doc.included.max_languages;
            }
            return null;
        },
        getIncludedReportImagesCount(licence_type) {
            // licence_type should be somethings like:
            // standard, enterprise
            const fullname_licence_type = licence_type + "_" + this.selected_billing_type;
            const index_licence_type = findWithAttr(
                this.pricing_licences_list,
                "id",
                fullname_licence_type
            );
            if (index_licence_type > -1) {
                const licence_doc = this.pricing_licences_list[index_licence_type];
                // Check if current plant have a custom_prices and also a custom included report_image_count
                const index_custom_price = findWithAttr(
                    licence_doc.custom_prices,
                    "plant",
                    this.selectedPlant
                );
                if (index_custom_price > -1) {
                    if (
                        licence_doc.custom_prices[index_custom_price].included &&
                        licence_doc.custom_prices[index_custom_price].included.report_image_count
                    ) {
                        return licence_doc.custom_prices[index_custom_price].included
                            .report_image_count;
                    }
                }
                return licence_doc.included.report_image_count;
            }
            return null;
        },
        getIncludedReportPreciseGPS(licence_type) {
            // licence_type should be somethings like:
            // standard, enterprise
            const fullname_licence_type = licence_type + "_" + this.selected_billing_type;
            const index_licence_type = findWithAttr(
                this.pricing_licences_list,
                "id",
                fullname_licence_type
            );
            if (index_licence_type > -1) {
                const licence_doc = this.pricing_licences_list[index_licence_type];
                // Check if current plant have a custom_prices and also a custom included report_gps
                const index_custom_price = findWithAttr(
                    licence_doc.custom_prices,
                    "plant",
                    this.selectedPlant
                );
                if (index_custom_price > -1) {
                    if (
                        licence_doc.custom_prices[index_custom_price].included &&
                        licence_doc.custom_prices[index_custom_price].included.report_gps
                    ) {
                        return licence_doc.custom_prices[index_custom_price].included
                            .report_gps;
                    }
                }
                return licence_doc.included.report_gps;
            }
            return null;
        },
        getPrice(licence_type, manual_type = null) {
            // licence_type should be somethings like:
            // standard, enterprise
            let fullname_licence_type
            if (!manual_type) {
                fullname_licence_type = licence_type + "_" + this.selected_billing_type;
            } else {
                fullname_licence_type = licence_type + "_" + manual_type;
            }
            const index_licence_type = findWithAttr(
                this.pricing_licences_list,
                "id",
                fullname_licence_type
            );
            if (index_licence_type > -1) {
                const licence_doc = this.pricing_licences_list[index_licence_type];
                // Check if current plant have a custom_prices and also a custom included price
                const index_custom_price = findWithAttr(
                    licence_doc.custom_prices,
                    "plant",
                    this.selectedPlant
                );
                if (index_custom_price > -1) {
                    if (
                        licence_doc.custom_prices[index_custom_price].price
                    ) {
                        return licence_doc.custom_prices[index_custom_price].price;
                    }
                }
                return licence_doc.price;
            }
            return null;
        },
        getDiscount(licence_type) {
            // licence_type should be somethings like:
            // standard, enterprise
            let fullname_licence_type = licence_type + "_" + this.selected_billing_type;

            const index_licence_type = findWithAttr(
                this.pricing_licences_list,
                "id",
                fullname_licence_type
            );
            if (index_licence_type > -1) {
                const licence_doc = this.pricing_licences_list[index_licence_type];
                // Check if current plant have a custom_prices and also a custom included discount
                const index_custom_price = findWithAttr(
                    licence_doc.custom_prices,
                    "plant",
                    this.selectedPlant
                );
                if (index_custom_price > -1) {
                    if (
                        licence_doc.custom_prices[index_custom_price].discount
                    ) {
                        return licence_doc.custom_prices[index_custom_price].discount;
                    }
                }
                if (licence_doc.discount) {
                    return licence_doc.discount
                } else {
                    return 0
                }
            }
            return 0;
        },
        getDiscountedPrice(licence_type) {
            return this.getPrice(licence_type) - ((this.getPrice(licence_type) * this.getDiscount(licence_type)) / 100)
        },
        getMontlyDiscountedPrice(licence_type) {
            return round(this.getDiscountedPrice(licence_type) / 12, 2)
        },

    }
}
