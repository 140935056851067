var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-3"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"select_subscription_type"},[_vm._v(" "+_vm._s(_vm.$t('lp_select_subscription_type'))+" ")]),_c('br'),_c('v-btn-toggle',[_c('v-btn',{attrs:{"height":"25","min-width":"150","dark":_vm.selected_billing_type == '1m'},on:{"click":function($event){_vm.selected_billing_type = '1m'}}},[_c('span',{class:_vm.selected_billing_type == '1m'
                ? 'secondary--text'
                : 'primary--text'},[_vm._v(" "+_vm._s(_vm.$t("lp_monthly"))+" ")])]),_c('v-btn',{attrs:{"height":"25","min-width":"150","dark":_vm.selected_billing_type == '12m'},on:{"click":function($event){_vm.selected_billing_type = '12m'}}},[_c('span',{class:_vm.selected_billing_type == '12m'
                ? 'secondary--text'
                : 'primary--text'},[_vm._v(" "+_vm._s(_vm.$t("lp_yearly"))+" ")])])],1)],1)],1),_c('div',{staticClass:"version_and_prices"},[_c('div',{staticClass:"prices_header"},[_c('v-row',{staticClass:"app_feature_header",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"header_empty_cell",class:_vm.$vuetify.breakpoint.smAndDown
              ? 'header_features_smallscreen'
              : 'header_features_not_smallscreen',attrs:{"cols":"6","md":"3","sm":"3"}}),_c('v-col',{staticClass:"\n            text-center\n            subtitle-2\n            app_feature_border_left\n            col_smallbusiness\n            app_header_version\n          ",attrs:{"cols":"2","sm":"3","md":"3"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('span',{class:_vm.$vuetify.breakpoint.smAndDown
                ? 'vertical_header_span'
                : 'horizontal_header_span'},[_vm._v(" "+_vm._s(_vm.$t("avf_header_smallbusiness")))]):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-card-title',{staticClass:"version_name"},[_vm._v(" Small Business ")]):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-card-text',[_c('span',{staticClass:"for_who"},[_vm._v(" "+_vm._s(_vm.$t("lp_for_who_smallbusiness"))+" ")])]):_vm._e()],1),_c('v-col',{staticClass:"\n            text-center\n            subtitle-2\n            app_feature_border_left\n            col_standard\n            app_header_version\n          ",attrs:{"cols":"2","sm":"3","md":"3"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('span',{class:_vm.$vuetify.breakpoint.smAndDown
                ? 'vertical_header_span'
                : 'horizontal_header_span'},[_vm._v(" "+_vm._s(_vm.$t("avf_header_standard")))]):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-card-title',{staticClass:"version_name"},[_vm._v(" Standard ")]):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-card-text',[_c('span',{staticClass:"for_who"},[_vm._v(" "+_vm._s(_vm.$t("lp_for_who_standard"))+" ")])]):_vm._e()],1),_c('v-col',{staticClass:"\n            text-center\n            subtitle-2\n            app_feature_border_left app_feature_border_right\n            col_enterprise\n            app_header_version\n          ",attrs:{"cols":"2","sm":"3","md":"3"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('span',{class:_vm.$vuetify.breakpoint.smAndDown
                ? 'vertical_header_span'
                : 'horizontal_header_span'},[_vm._v(" "+_vm._s(_vm.$t("avf_header_enterprise")))]):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-card-title',{staticClass:"version_name"},[_vm._v(" Enterprise ")]):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-card-text',[_c('span',{staticClass:"for_who"},[_vm._v(" "+_vm._s(_vm.$t("lp_for_who_enterprise"))+" ")])]):_vm._e()],1)],1)],1),_vm._l((_vm.app_versions_features),function(included_feature){return _c('v-row',{key:included_feature.feature_name,staticClass:"app_feature_row",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-1 pl-3 subtitle-2 col_feature_desc",attrs:{"cols":"6","sm":"3","md":"3"}},[_vm._v(_vm._s(included_feature.feature_name)+" "),_c('v-tooltip',{staticStyle:{"opacity":"1"},attrs:{"bottom":"","max-width":"400","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"small":""}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(included_feature.feature_tooltip))])])],1),_c('v-col',{staticClass:"\n          text-center\n          app_feature_border_left\n          col_smallbusiness\n          feature_value\n        ",attrs:{"cols":"2","sm":"3","md":"3"}},[(
            included_feature.small_business === false ||
            included_feature.small_business == 0
          )?_c('span',[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-close ")])],1):(included_feature.small_business === true)?_c('span',[_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v(" mdi-check ")])],1):(included_feature.small_business === 999)?_c('span',[_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v(" mdi-infinity ")])],1):_c('span',{staticClass:"subtitle-2 span_feature_value"},[_vm._v(_vm._s(included_feature.small_business))])]),_c('v-col',{staticClass:"text-center app_feature_border_left col_standard feature_value",attrs:{"cols":"2","sm":"3","md":"3"}},[(
            included_feature.standard === false ||
            included_feature.standard == 0
          )?_c('span',[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-close ")])],1):(included_feature.standard === true)?_c('span',[_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v(" mdi-check ")])],1):(included_feature.standard === 999)?_c('span',[_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v(" mdi-infinity ")])],1):_c('span',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(included_feature.standard))])]),_c('v-col',{staticClass:"\n          text-center\n          app_feature_border_left app_feature_border_right\n          col_enterprise\n          feature_value\n        ",attrs:{"cols":"2","sm":"3","md":"3"}},[(
            included_feature.enterprise === false ||
            included_feature.enterprise == 0
          )?_c('span',[_c('v-icon',{staticClass:"span_feature_symbol",attrs:{"small":"","color":"red"}},[_vm._v(" mdi-close ")])],1):(included_feature.enterprise === true)?_c('span',[_c('v-icon',{staticClass:"span_feature_symbol",attrs:{"small":"","color":"green"}},[_vm._v(" mdi-check ")])],1):(included_feature.enterprise === 999)?_c('span',[_c('v-icon',{staticClass:"span_feature_symbol",staticStyle:{"font-weight":"bold !important"},attrs:{"small":"","color":"green"}},[_vm._v(" mdi-infinity ")])],1):_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(included_feature.enterprise))])])],1)}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"price_empty_cell",attrs:{"cols":"6","sm":"3","md":"3"}}),_c('v-col',{staticClass:"\n          text-center\n          subtitle-2\n          app_feature_border_left\n          col_smallbusiness\n          bottom_border\n          cell_version_price\n          price_smallbusiness\n        ",attrs:{"cols":"2","sm":"3","md":"3"}},[_c('cardVersionPrice',{attrs:{"selected_billing_type":_vm.selected_billing_type,"version":"smallbusiness"}})],1),_c('v-col',{staticClass:"\n          text-center\n          subtitle-2\n          app_feature_border_left\n          col_standard\n          bottom_border\n          cell_version_price\n          price_standard\n        ",attrs:{"cols":"2","sm":"3","md":"3"}},[_c('cardVersionPrice',{attrs:{"selected_billing_type":_vm.selected_billing_type,"version":"standard"}})],1),_c('v-col',{staticClass:"\n          text-center\n          subtitle-2\n          app_feature_border_left app_feature_border_right\n          col_enterprise\n          bottom_border\n          cell_version_price\n          price_enterprise\n        ",attrs:{"cols":"2","sm":"3","md":"3"}},[_c('cardVersionPrice',{attrs:{"selected_billing_type":_vm.selected_billing_type,"version":"enterprise"}})],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }