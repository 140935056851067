<template>
  <div class="white black--text">
    <!-- <div class="black--text">
      XS: {{ $vuetify.breakpoint.xs }} <br />
      SM: {{ $vuetify.breakpoint.sm }} <br />
      MD: {{ $vuetify.breakpoint.md }} <br />
      LG: {{ $vuetify.breakpoint.lg }} <br />
      XL: {{ $vuetify.breakpoint.xl }} <br />
    </div> -->

    <v-row>
      <v-col
        cols="12"
        offset-md="0"
        md="12"
        offset-lg="1"
        lg="10"
        offset-xl="3"
        xl="6"
      >
        <div v-bind:style="{ 'margin-bottom': under_headings_padding }">
          <heading1Section
            color="black"
            :dense="true"
            :text="$t('lp_h1_prices')"
          />
          <heading2Section
            color="black"
            :dense="true"
            :text="$t('lp_start_now_your_free_trial_period')"
          />
        </div>


        <versionsFeaturesForSmallDevices
          class="mx-4"
          :selected_billing_type="selected_billing_type"
          v-if="$vuetify.breakpoint.smAndDown"
        />
        <!-- <div style="height: 100px"></div> -->
        <versionsFeaturesTableCompare
          class="mx-4"
          :selected_billing_type="selected_billing_type"
          v-if="!$vuetify.breakpoint.smAndDown"
        />
      </v-col>
    </v-row>

    <seeAlso v-if="!wait_seeAlsoBtns" :back_section="back_section" />
    <btnBackHome :back_section="back_section" />
  </div>
</template>

<script>
import heading1Section from "@/components/landings/heading1Section.vue";
import heading2Section from "@/components/landings/heading2Section.vue";
import seeAlso from "@/components/landings/blocks/seeAlso.vue";
import btnBackHome from "@/components/landings/btnBackHome.vue";
import AOS from "aos";
import versionsFeaturesTableCompare from "@/components/landings/blocks/versionsFeaturesTableCompare";
import versionsFeaturesForSmallDevices from "@/components/landings/blocks/versionsFeaturesForSmallDevices";

// import cardVersionPrice from "@/components/landings/blocks/cardVersionPrice.vue";

export default {
  metaInfo: {
    title: "Prezzi di SafetyFirst",
  },

  props: ["from_section"],
  components: {
    heading1Section,
    heading2Section,
    seeAlso,
    btnBackHome,
    versionsFeaturesTableCompare,
    versionsFeaturesForSmallDevices,
    //
    // cardVersionPrice,
  },
  data: () => ({
    back_section: null,
    wait_seeAlsoBtns: true,
    //
    selected_billing_type: "12m",
    // suffix_lenght_months: "_12m",
  }),
  methods: {
    // =======================================================================================================
    // Look at app_versions_features.js for business logic behind prices and included for each licence type
    // =======================================================================================================
  },
  mounted() {
    // Ask vuex to fetch pricing information from DB
    this.$store.dispatch("pricing/act_get_pricing_info", this.selectedPlant);

    AOS.init();
    if (this.$route.params.from_section) {
      this.back_section = this.$route.params.from_section;
      console.log("back to:", this.$route.params.from_section);
    }
    setInterval(() => {
      this.wait_seeAlsoBtns = false;
    }, 80);
  },
  created() {},
  computed: {
    under_headings_padding() {
      if (this.$vuetify.breakpoint.xs) {
        return "1rem";
      } else if (this.$vuetify.breakpoint.sm) {
        return "1.5rem";
      } else if (this.$vuetify.breakpoint.md) {
        return "2rem";
      } else if (this.$vuetify.breakpoint.lg) {
        return "3rem";
      } else if (this.$vuetify.breakpoint.xl) {
        return "5rem";
      }
    },
  },
  watch: {},
};
</script>


<style scoped>
/* Card style */
.licence_version {
  font-weight: 800;
}
</style>

<style scoped>
/* .v-card__title {
  font-size: 2rem;
  justify-content: center;
} */
</style>
