<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card class="card_smallbusiness">
          <!-- Plan length select -->
          <v-card-text class="text-center">
            <span class="select_subscription_type">
              {{ $t("lp_select_subscription_type") }}
            </span>
            <v-btn-toggle>
              <v-btn
                height="25"
                min-width="100"
                @click="selected_billing_type = '1m'"
                :dark="selected_billing_type == '1m'"
              >
                <span
                  v-bind:class="
                    selected_billing_type == '1m'
                      ? 'secondary--text'
                      : 'primary--text'
                  "
                >
                  {{ $t("lp_monthly") }}
                </span>
              </v-btn>
              <v-btn
                height="25"
                min-width="100"
                @click="selected_billing_type = '12m'"
                :dark="selected_billing_type == '12m'"
              >
                <span
                  v-bind:class="
                    selected_billing_type == '12m'
                      ? 'secondary--text'
                      : 'primary--text'
                  "
                >
                  {{ $t("lp_yearly") }}
                </span>
              </v-btn>
            </v-btn-toggle>
          </v-card-text>
          <v-card-title class="version_name"> SmallBusiness </v-card-title>
          <v-card-text class="text-center">
            <!-- For who? -->
            <span class="for_who">
              {{ $t("lp_for_who_smallbusiness") }}
            </span>
            <!-- Price based on montly or yearly selection -->
            <cardVersionPrice
              class="version_price"
              :selected_billing_type="selected_billing_type"
              version="smallbusiness"
            />
            <!-- Separator -->
            <hr class="mt-2 mb-6" />
            <!-- Features -->
            <v-row
              no-gutters
              v-for="included_feature in app_versions_features"
              :key="included_feature.feature_name"
              class="text-center feature_row"
            >
              <!-- Feature description -->
              <v-col cols="9" class="text-left">
                <span class="feature_description caption">
                  {{ included_feature.feature_name }}
                </span>

                <v-tooltip bottom max-width="90%" color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-on="on">mdi-information-outline</v-icon>
                  </template>
                  <span>{{ included_feature.feature_tooltip }}</span>
                </v-tooltip>
              </v-col>
              <!-- Feature value -->
              <v-col cols="3" class="text-center">
                <span
                  v-if="
                    included_feature.small_business === false ||
                    included_feature.small_business == 0
                  "
                >
                  <v-icon small color="red"> mdi-close </v-icon>
                </span>
                <span v-else-if="included_feature.small_business === true">
                  <v-icon small color="green"> mdi-check </v-icon>
                </span>

                <span v-else-if="included_feature.small_business === 999">
                  <v-icon small color="green"> mdi-infinity </v-icon>
                </span>

                <span v-else class="span_feature_value">{{
                  included_feature.small_business
                }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- -------------- -->

      <v-col cols="12">
        <v-card class="card_standard">
          <!-- Plan length select -->
          <v-card-text class="text-center">
            <span class="select_subscription_type">
              {{ $t("lp_select_subscription_type") }}
            </span>
            <v-btn-toggle>
              <v-btn
                height="25"
                min-width="100"
                @click="selected_billing_type = '1m'"
                :dark="selected_billing_type == '1m'"
              >
                <span
                  v-bind:class="
                    selected_billing_type == '1m'
                      ? 'secondary--text'
                      : 'primary--text'
                  "
                >
                  {{ $t("lp_monthly") }}
                </span>
              </v-btn>
              <v-btn
                height="25"
                min-width="100"
                @click="selected_billing_type = '12m'"
                :dark="selected_billing_type == '12m'"
              >
                <span
                  v-bind:class="
                    selected_billing_type == '12m'
                      ? 'secondary--text'
                      : 'primary--text'
                  "
                >
                  {{ $t("lp_yearly") }}
                </span>
              </v-btn>
            </v-btn-toggle>
          </v-card-text>
          <v-card-title class="version_name"> Standard </v-card-title>
          <v-card-text class="text-center">
            <!-- For who? -->
            <span class="for_who">
              {{ $t("lp_for_who_standard") }}
            </span>
            <!-- Price based on montly or yearly selection -->
            <cardVersionPrice
              class="version_price"
              :selected_billing_type="selected_billing_type"
              version="standard"
            />
            <!-- Separator -->
            <hr class="mt-2 mb-6" />
            <!-- Features -->
            <v-row
              no-gutters
              v-for="included_feature in app_versions_features"
              :key="included_feature.feature_name"
              class="text-center feature_row"
            >
              <!-- Feature description -->
              <v-col cols="9" class="text-left">
                <span class="feature_description caption">
                  {{ included_feature.feature_name }}
                </span>

                <v-tooltip bottom max-width="90%" color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-on="on">mdi-information-outline</v-icon>
                  </template>
                  <span>{{ included_feature.feature_tooltip }}</span>
                </v-tooltip>
              </v-col>
              <!-- Feature value -->
              <v-col cols="3" class="text-center">
                <span
                  v-if="
                    included_feature.standard === false ||
                    included_feature.standard == 0
                  "
                >
                  <v-icon small color="red"> mdi-close </v-icon>
                </span>
                <span v-else-if="included_feature.standard === true">
                  <v-icon small color="green"> mdi-check </v-icon>
                </span>

                <span v-else-if="included_feature.standard === 999">
                  <v-icon small color="green"> mdi-infinity </v-icon>
                </span>

                <span v-else class="span_feature_value">{{
                  included_feature.standard
                }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- -------------- -->

      <v-col cols="12">
        <v-card class="card_enterprise">
          <!-- Plan length select -->
          <v-card-text class="text-center">
            <span class="select_subscription_type">
              {{ $t("lp_select_subscription_type") }}
            </span>
            <v-btn-toggle>
              <v-btn
                height="25"
                min-width="100"
                @click="selected_billing_type = '1m'"
                :dark="selected_billing_type == '1m'"
              >
                <span
                  v-bind:class="
                    selected_billing_type == '1m'
                      ? 'secondary--text'
                      : 'primary--text'
                  "
                >
                  {{ $t("lp_monthly") }}
                </span>
              </v-btn>
              <v-btn
                height="25"
                min-width="100"
                @click="selected_billing_type = '12m'"
                :dark="selected_billing_type == '12m'"
              >
                <span
                  v-bind:class="
                    selected_billing_type == '12m'
                      ? 'secondary--text'
                      : 'primary--text'
                  "
                >
                  {{ $t("lp_yearly") }}
                </span>
              </v-btn>
            </v-btn-toggle>
          </v-card-text>
          <v-card-title class="version_name"> Enterprise </v-card-title>
          <v-card-text class="text-center">
            <!-- For who? -->
            <span class="for_who">
              {{ $t("lp_for_who_enterprise") }}
            </span>
            <!-- Price based on montly or yearly selection -->
            <cardVersionPrice
              class="version_price"
              :selected_billing_type="selected_billing_type"
              version="enterprise"
            />
            <!-- Separator -->
            <hr class="mt-2 mb-6" />
            <!-- Features -->
            <v-row
              no-gutters
              v-for="included_feature in app_versions_features"
              :key="included_feature.feature_name"
              class="text-center feature_row"
            >
              <!-- Feature description -->
              <v-col cols="9" class="text-left">
                <span class="feature_description caption">
                  {{ included_feature.feature_name }}
                </span>

                <v-tooltip bottom max-width="90%" color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-on="on">mdi-information-outline</v-icon>
                  </template>
                  <span>{{ included_feature.feature_tooltip }}</span>
                </v-tooltip>
              </v-col>
              <!-- Feature value -->
              <v-col cols="3" class="text-center">
                <span
                  v-if="
                    included_feature.enterprise === false ||
                    included_feature.enterprise == 0
                  "
                >
                  <v-icon small color="red"> mdi-close </v-icon>
                </span>
                <span v-else-if="included_feature.enterprise === true">
                  <v-icon small color="green"> mdi-check </v-icon>
                </span>

                <span v-else-if="included_feature.enterprise === 999">
                  <v-icon small color="green"> mdi-infinity </v-icon>
                </span>

                <span v-else class="span_feature_value">{{
                  included_feature.enterprise
                }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- ************************************************************ -->
  </div>
</template>

<script>
import app_versions_features from "@/mixins/app_versions_features";
import cardVersionPrice from "@/components/landings/blocks/cardVersionPrice.vue";

export default {
  // props: {
  //   selected_billing_type: {
  //     type: String,
  //   },
  // },
  mixins: [app_versions_features],

  components: {
    cardVersionPrice,
  },
  data: () => ({
    selected_billing_type: "12m",
    // suffix_lenght_months: "_12m",
  }),
  methods: {},
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped>
.select_subscription_type {
  font-weight: bold;
}

.version_name {
  font-size: 2rem !important;
  font-weight: 800;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

/* .version_and_prices {
  box-shadow: 7px 7px 6px 0px darkgrey;
} */

.header_empty_cell {
  box-shadow: -7px -7px 8px 8px white;
}

.feature_description {
  /* font-weight: 400; */
}

.for_who {
  font-style: italic;
  font-weight: 400;
  text-align: center;
}

.feature_tooltip {
  max-width: 100px;
}

.mdi-close::before {
  font-weight: bold;
  font-size: 1.2rem;
}
.mdi-check::before {
  font-weight: bold;
  font-size: 1.2rem;
}

.mdi-infinity::before {
  font-weight: bold;
  font-size: 1.2rem;
}

.span_feature_symbol {
  font-size: 2rem;
}

.span_feature_value {
  font-weight: bold;
  /* font-size: 1.2rem; */
}
.col_feature_desc {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  min-height: 30px;
  display: flex;
  align-items: center;
}

.app_header_version {
  max-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.feature_value {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_smallbusiness {
  background-color: rgb(255 254 235 / 30%);
}

.card_standard {
  background-color: rgb(255 253 184 / 30%);
}

.card_enterprise {
  background-color: rgb(255 250 112 / 30%);
}

.app_feature_header {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: darkgray;
}

.app_feature_row {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: darkgray;
}

.feature_row:nth-child(odd) {
  /* background: rgba(226, 226, 226, 0.75); */
}
.feature_row:nth-child(even) {
  background: rgba(226, 226, 226, 0.25);
}

.app_feature_border_left {
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: darkgray;
}

.app_feature_border_right {
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: darkgray;
}

.vertical_header_col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header_features_not_smallscreen {
  display: flex;
  align-items: center;
}

.header_features_smallscreen {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.horizontal_header_span {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  max-width: 100px;
}

.vertical_header_span {
  writing-mode: vertical-rl;
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-height: 145px;
}

.price_empty_cell {
  box-shadow: -3px 8px 3px 5px white;
}

.bottom_border {
  border-bottom-style: solid !important;
  border-bottom: black;
  border-bottom-width: 1px;
}

.cell_version_price {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.v-tooltip__content.menuable__content__active {
  opacity: 1 !important;
}
</style>
