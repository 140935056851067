<template>
  <div>
    <v-row class="mb-3">
      <!-- Montly of yarly select -->
      <v-col cols="12" class="text-center">
        <span class="select_subscription_type">
            {{ $t('lp_select_subscription_type')}}
            </span>
            <br>
        <v-btn-toggle>
          <v-btn
            height="25"
            min-width="150"
            @click="selected_billing_type = '1m'"
            :dark="selected_billing_type == '1m'"
          >
            <span
              v-bind:class="
                selected_billing_type == '1m'
                  ? 'secondary--text'
                  : 'primary--text'
              "
            >
              {{ $t("lp_monthly") }}
            </span>
          </v-btn>
          <v-btn
            height="25"
            min-width="150"
            @click="selected_billing_type = '12m'"
            :dark="selected_billing_type == '12m'"
          >
            <span
              v-bind:class="
                selected_billing_type == '12m'
                  ? 'secondary--text'
                  : 'primary--text'
              "
            >
              {{ $t("lp_yearly") }}
            </span>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <div class="version_and_prices">
      <div class="prices_header">
        <!-- HEADERS -->
        <v-row no-gutters class="app_feature_header">
          <!-- FEATURES -->
          <v-col
            cols="6"
            md="3"
            sm="3"
            class="header_empty_cell"
            v-bind:class="
              $vuetify.breakpoint.smAndDown
                ? 'header_features_smallscreen'
                : 'header_features_not_smallscreen'
            "
          >
          </v-col>

          <!-- SMALLBUSINESS -->
          <v-col
            cols="2"
            sm="3"
            md="3"
            class="
              text-center
              subtitle-2
              app_feature_border_left
              col_smallbusiness
              app_header_version
            "
          >
            <span
              v-if="$vuetify.breakpoint.smAndDown"
              v-bind:class="
                $vuetify.breakpoint.smAndDown
                  ? 'vertical_header_span'
                  : 'horizontal_header_span'
              "
            >
              {{ $t("avf_header_smallbusiness") }}</span
            >
            <v-card-title
              class="version_name"
              v-if="!$vuetify.breakpoint.smAndDown"
            >
              Small Business
            </v-card-title>
            <v-card-text v-if="!$vuetify.breakpoint.smAndDown">
              <!-- For who? -->
              <span class="for_who">
                {{ $t("lp_for_who_smallbusiness") }}
              </span>
            </v-card-text>
          </v-col>
          <!-- STANDARD -->
          <v-col
            cols="2"
            sm="3"
            md="3"
            class="
              text-center
              subtitle-2
              app_feature_border_left
              col_standard
              app_header_version
            "
          >
            <span
              v-if="$vuetify.breakpoint.smAndDown"
              v-bind:class="
                $vuetify.breakpoint.smAndDown
                  ? 'vertical_header_span'
                  : 'horizontal_header_span'
              "
            >
              {{ $t("avf_header_standard") }}</span
            >
            <v-card-title
              class="version_name"
              v-if="!$vuetify.breakpoint.smAndDown"
            >
              Standard
            </v-card-title>
            <v-card-text v-if="!$vuetify.breakpoint.smAndDown">
              <!-- For who? -->
              <span class="for_who">
                {{ $t("lp_for_who_standard") }}
              </span>
            </v-card-text>
          </v-col>
          <!-- ENTERPRISE -->
          <v-col
            cols="2"
            sm="3"
            md="3"
            class="
              text-center
              subtitle-2
              app_feature_border_left app_feature_border_right
              col_enterprise
              app_header_version
            "
          >
            <span
              v-if="$vuetify.breakpoint.smAndDown"
              v-bind:class="
                $vuetify.breakpoint.smAndDown
                  ? 'vertical_header_span'
                  : 'horizontal_header_span'
              "
            >
              {{ $t("avf_header_enterprise") }}</span
            >
            <v-card-title
              class="version_name"
              v-if="!$vuetify.breakpoint.smAndDown"
            >
              Enterprise
            </v-card-title>
            <v-card-text v-if="!$vuetify.breakpoint.smAndDown">
              <!-- For who? -->
              <span class="for_who">
                {{ $t("lp_for_who_enterprise") }}
              </span>
            </v-card-text>
          </v-col>
        </v-row>
      </div>

      <!-- FEATURES ROWS -->
      <v-row
        no-gutters
        v-for="included_feature in app_versions_features"
        :key="included_feature.feature_name"
        class="app_feature_row"
      >
        <!-- FEATURE NAME -->
        <v-col
          cols="6"
          sm="3"
          md="3"
          class="py-1 pl-3 subtitle-2 col_feature_desc"
          >{{ included_feature.feature_name }}
          <v-tooltip bottom max-width="400" color="primary" style="opacity: 1">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-1" small v-on="on"
                >mdi-information-outline</v-icon
              >
            </template>
            <span>{{ included_feature.feature_tooltip }}</span>
          </v-tooltip>
        </v-col>
        <!-- FEATURE SMALLBUSINESS -->
        <v-col
          cols="2"
          sm="3"
          md="3"
          class="
            text-center
            app_feature_border_left
            col_smallbusiness
            feature_value
          "
        >
          <span
            v-if="
              included_feature.small_business === false ||
              included_feature.small_business == 0
            "
          >
            <v-icon small color="red"> mdi-close </v-icon>
          </span>
          <span v-else-if="included_feature.small_business === true">
            <v-icon small color="green"> mdi-check </v-icon>
          </span>

          <span v-else-if="included_feature.small_business === 999">
            <v-icon small color="green"> mdi-infinity </v-icon>
          </span>

          <span v-else class="subtitle-2 span_feature_value">{{
            included_feature.small_business
          }}</span>
        </v-col>
        <!-- FEATURE STANDARD -->
        <v-col
          cols="2"
          sm="3"
          md="3"
          class="text-center app_feature_border_left col_standard feature_value"
        >
          <span
            v-if="
              included_feature.standard === false ||
              included_feature.standard == 0
            "
          >
            <v-icon small color="red"> mdi-close </v-icon>
          </span>
          <span v-else-if="included_feature.standard === true">
            <v-icon small color="green"> mdi-check </v-icon>
          </span>
          <span v-else-if="included_feature.standard === 999">
            <v-icon small color="green"> mdi-infinity </v-icon>
          </span>
          <span v-else class="subtitle-2">
            {{ included_feature.standard }}</span
          >
        </v-col>
        <!-- FEATURE ENTERPRISE -->
        <v-col
          cols="2"
          sm="3"
          md="3"
          class="
            text-center
            app_feature_border_left app_feature_border_right
            col_enterprise
            feature_value
          "
        >
          <span
            v-if="
              included_feature.enterprise === false ||
              included_feature.enterprise == 0
            "
          >
            <v-icon small color="red" class="span_feature_symbol">
              mdi-close
            </v-icon>
          </span>
          <span v-else-if="included_feature.enterprise === true">
            <v-icon small color="green" class="span_feature_symbol">
              mdi-check
            </v-icon>
          </span>
          <span v-else-if="included_feature.enterprise === 999">
            <v-icon
              small
              color="green"
              style="font-weight: bold !important"
              class="span_feature_symbol"
            >
              mdi-infinity
            </v-icon>
          </span>
          <span v-else class="subtitle-2">{{
            included_feature.enterprise
          }}</span>
        </v-col>
      </v-row>

      <!-- PRICES ROW -->
      <v-row no-gutters class="">
        <!-- EMPTY CELL -->
        <v-col cols="6" sm="3" md="3" class="price_empty_cell"> </v-col>

        <!-- SMALLBUSINESS -->
        <v-col
          cols="2"
          sm="3"
          md="3"
          class="
            text-center
            subtitle-2
            app_feature_border_left
            col_smallbusiness
            bottom_border
            cell_version_price
            price_smallbusiness
          "
        >
          <cardVersionPrice
            :selected_billing_type="selected_billing_type"
            version="smallbusiness"
          />
        </v-col>
        <!-- STANDARD -->
        <v-col
          cols="2"
          sm="3"
          md="3"
          class="
            text-center
            subtitle-2
            app_feature_border_left
            col_standard
            bottom_border
            cell_version_price
            price_standard
          "
        >
          <cardVersionPrice
            :selected_billing_type="selected_billing_type"
            version="standard"
          />
        </v-col>
        <!-- ENTERPRISE -->
        <v-col
          cols="2"
          sm="3"
          md="3"
          class="
            text-center
            subtitle-2
            app_feature_border_left app_feature_border_right
            col_enterprise
            bottom_border
            cell_version_price
            price_enterprise
          "
        >
          <cardVersionPrice
            :selected_billing_type="selected_billing_type"
            version="enterprise"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import app_versions_features from "@/mixins/app_versions_features";
import cardVersionPrice from "@/components/landings/blocks/cardVersionPrice.vue";

export default {
  // props: {
  //   selected_billing_type: {
  //     type: String,
  //   },
  // },
  mixins: [app_versions_features],

  components: {
    cardVersionPrice,
  },
  data: () => ({
    selected_billing_type:'12m',
    // suffix_lenght_months: "_12m",
  }),
  methods: {},
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped>
.version_name {
  font-size: 2rem !important;
  font-weight: 800;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.version_and_prices {
  box-shadow: 7px 7px 6px 0px darkgrey;
}

.header_empty_cell {
  box-shadow: -7px -7px 8px 8px white;
}

.for_who {
  font-style: italic;
  font-weight: 400;
  text-align: center;
}

.mdi-close::before {
  font-weight: bold;
  font-size: 1.2rem;
}
.mdi-check::before {
  font-weight: bold;
  font-size: 1.2rem;
}

.mdi-infinity::before {
  font-weight: bold;
  font-size: 1.2rem;
}

.span_feature_symbol {
  font-size: 2rem;
}

.span_feature_value {
  font-size: 1.2rem;
}
.col_feature_desc {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  min-height: 30px;
  display: flex;
  align-items: center;
}

.app_header_version {
  /* max-height: 140px; */
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.feature_value {
  display: flex;
  align-items: center;
  justify-content: center;
}

.col_smallbusiness {
  background-color: rgb(255 254 235 / 30%);
}

.col_standard {
  background-color: rgb(255 253 184 / 30%);
}

.col_enterprise {
  background-color: rgb(255 250 112 / 30%);
}

.app_feature_header {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: darkgray;
}

.app_feature_row {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: darkgray;
}

.app_feature_row:nth-child(odd) {
  background: rgba(226, 226, 226, 0.75);
}
.app_feature_row:nth-child(even) {
  background: rgba(226, 226, 226, 0.25);
}

.app_feature_border_left {
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: darkgray;
}

.app_feature_border_right {
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: darkgray;
}

.vertical_header_col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header_features_not_smallscreen {
  display: flex;
  align-items: center;
}

.header_features_smallscreen {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.horizontal_header_span {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  max-width: 100px;
}

.vertical_header_span {
  writing-mode: vertical-rl;
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-height: 145px;
}

.price_empty_cell {
  box-shadow: -3px 8px 3px 5px white;
}

.bottom_border {
  border-bottom-style: solid !important;
  border-bottom: black;
  border-bottom-width: 1px;
}

.cell_version_price {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.v-tooltip__content.menuable__content__active {
  opacity: 1 !important;
}
</style>
